import React, { useEffect } from "react";
import ClaimsList from "components/claims/ClaimsList";
import ClaimView from "components/claims/ClaimView";
import Drawer from "components/ui/Drawer";
import { resetSelectedClaim } from "../components/claims/slice";
import { useAppDispatch, useAppSelector } from "../hooks/redux-hooks";
import { selectSelectedClaim } from "../components/claims/slice/selectors";

interface ClaimsProps { }

const Claims: React.FC<ClaimsProps> = () => {
  const dispatch = useAppDispatch();
  const open = !!useAppSelector(selectSelectedClaim);

  useEffect(() => () => {
    dispatch(resetSelectedClaim());
  }, [dispatch]);


  return (
    <>
      <Drawer opened={open} onClose={() => dispatch(resetSelectedClaim())} anchor="right" >
        <ClaimView />
      </Drawer>
      <ClaimsList />
    </>
  );
};

export default Claims;
