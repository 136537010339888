import { ITravellerDTO } from "./DTO/ITravellerDto";

export type Nullable<T> = T | null;

export interface IIndexable<T> {
  [key: string]: T;
}

export type ClaimId = {
  id: number;
  identifier: string;
  subject: string;
};

export type StatusType =
  | "active"
  | "closed"
  | "reopen"
  | "approvedReimbursed"
  | "approvedPaid"
  | "duplicate"
  | string;

export type EventType = "claim" | "user" | "policy" | "claimItem";

export interface IClaim {
  claim: ClaimId;
  dateCreated: string;
  name: string;
  policyCode: Nullable<string>;
  searchString: string;
  claimStatus: StatusType;
  subject: string;
  policyId?: number;
  userId: number;
  intent:
    | "baggageDelay"
    | "passport_claim"
    | "initial"
    | "medicalAssistance"
    | "lostSomething";
}

export type IUserProfile = {
  profilePicture: string;
  created: string;
  nickName: string;
};

export interface IPageMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export enum ExpenseMethod {
  NONE = "none",
  ZENNER_CARD = "zenner card",
  BANK_ACCOUNT = "bank account"
}

export enum ClaimStatus {
  Active = "active",
  Closed = "closed",
  Reopen = "reopen",
  "Approved Reimbursed" = "approvedReimbursed",
  "Approved Paid" = "approvedPaid",
  Duplicate = 'duplicate',
}

export type AutocompleteItem = { label: string; id: number | string };

type CoverageInfo = {
  title: string;
  description: string;
};

export type MobileUserPolicyDto = {
  coverages: CoverageInfo[];
  tripStartDate: string;
  tripEndDate: string;
  travellers: ITravellerDTO[];
  id: number;
  userPolicyUniqueUUID: string;
  groupCode: string;
};

export type Options = {
  weekday: "long" | "short" | "narrow" | undefined;
  day: "numeric" | "2-digit" | undefined;
  year: "numeric" | "2-digit" | undefined;
  month: "numeric" | "2-digit" | "narrow" | "short" | "long" | undefined;
  hour?: "numeric" | "2-digit" | undefined;
  minute?: "numeric" | "2-digit" | undefined;
  second?: "numeric" | "2-digit" | undefined;
};
