import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchUsersPage } from "./slice/thunks";
import {
  isComponentLoading,
  selectNewUserModalOpen,
  selectUsers,
  selectUsersPagination,
  selectUsersSearchText
} from "./slice/selectors";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Table from "../../app/components/Table";
import CommonCellRenderer from "../../app/components/Table/CellRenderers/CommonCellRenderer";
import AvatarCallRenderer from "../../app/components/Table/CellRenderers/AvatarCallRenderer";
import { GRID_AREA_SECTION_COLLECTION } from "../../constants/common";
import PaginationController from "../PaginationController";
import {
  resetSelected,
  setSelectedRowIndex
} from "../collectionTable/collectionTableSlice";
import { RowSelectedEvent } from "ag-grid-community/dist/lib/events";
import {
  openNewUserModal,
  setSelectedUser,
  updateUserSearchText
} from "./slice";
import Filter from "../Filter";
import Button from "@mui/material/Button";
import AddUserModal from "./Modals/AddUserModal";
import SpinnerLoader from "../ui/loaders/SpinnerLoader/SpinnerLoader";

const ADD_USER_BUTTON_CLASS = "add-user-button";
const CLASS_TABLE_WRAPPER = "table-wrapper";

interface AdvisorsListProps {}

const TableWrapper = styled(Box)`
  &.${CLASS_TABLE_WRAPPER} {
    flex-grow: 1;
  }
`;

const ClaimsCollectionContainer = styled.div`

  h2 {
    font-size: 32px;
    margin: 0 0 24px;
  }

  &.${GRID_AREA_SECTION_COLLECTION} {
    padding: 16px;
    height: 100%;
    background: #f4f4f4;
    display: flex;
    flex-direction: column;
    & > .filter-container {
      display: flex;
      align-items: center;
      margin-block-end: 20px;
      gap: clamp(40px, 50vw, 10rem);
    
      & > .collection-filter-element{
        flex: 4;
      }

      & > .${ADD_USER_BUTTON_CLASS} {
        font-weight: 500;
        font-size: 13px;
        flex: 1;
        text-transform: none;
        border: 1px solid var(--primary-color);
        background-color: #fff;
        color: var(--primary-color);
        box-shadow: var(--box-shadow);
        padding: 10px 20px;
      }
    }
  }
`;

const AdvisorsList: React.FC<AdvisorsListProps> = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const pagination = useAppSelector(selectUsersPagination);
  const searchText = useAppSelector(selectUsersSearchText);
  const isLoading = useAppSelector(isComponentLoading);
  const newUserModalOpen = useAppSelector(selectNewUserModalOpen);

  const handleRowSelected = useCallback(
    (event: RowSelectedEvent) => {
      dispatch(setSelectedUser(event.data));
      dispatch(setSelectedRowIndex(event.node.rowIndex));
    },
    [dispatch]
  );

  const handleNewUserClicked = useCallback(() => {
    dispatch(openNewUserModal());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUsersPage(0));
    dispatch(resetSelected());
  }, [dispatch]);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : (
    <>
      {Boolean(users) && (
        <TableWrapper className={`${CLASS_TABLE_WRAPPER} ag-theme-alpine`}>
          <Table
            rowData={users!}
            cellRenderers={{
              avatarCallRenderer: AvatarCallRenderer,
              commonCellRenderer: CommonCellRenderer
            }}
            columnDef={[
              { name: "name", componentName: "commonCellRenderer" },
              { name: "email", componentName: "commonCellRenderer" },
              { name: "advisorId", componentName: "commonCellRenderer" },
              { name: "active", componentName: "CheckboxCellComponent" }
            ]}
            onRowSelected={handleRowSelected}
          />
          <PaginationController
            pagination={pagination}
            fetchFunction={fetchUsersPage}
          />
        </TableWrapper>
      )}
    </>
  );

  return (
    <ClaimsCollectionContainer className={GRID_AREA_SECTION_COLLECTION}>
      <h2>Travel Advisors</h2>
      <Box className="filter-container">
        <Filter
          updateFilterFunction={updateUserSearchText}
          fetchResultsFunction={fetchUsersPage}
          label="Search by name or Email"
          searchText={searchText}
        />
           <Button
          className={ADD_USER_BUTTON_CLASS}
          variant="contained"
          onClick={handleNewUserClicked}
        >
          + Add new advisor
        </Button>
      </Box>
      {content}
      {newUserModalOpen && <AddUserModal isOpen={newUserModalOpen} />}
    </ClaimsCollectionContainer>
  );
};

export default React.memo(AdvisorsList);
