import React, { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import useInput from "../hooks/useInput";
import { useDebouncedValueUpdate } from "../hooks/useDebouncedValueUpdate";
import { useAppDispatch } from "../hooks/redux-hooks";
import { CLASS_COLLECTION_FILTER_ELEMENT } from "../constants/common";
import styled from "styled-components";

interface FilterProps {
  label: string;
  searchText: string;
  // @ts-ignore
  updateFilterFunction: ActionCreatorWithoutPayload<string, string>;
  // @ts-ignore
  fetchResultsFunction: ActionCreatorWithoutPayload<string, string>;
}

const FilterContainer = styled(FormControl)`
  &.${CLASS_COLLECTION_FILTER_ELEMENT} {
    font-size: 13px;
    width: 100%;
    
    & > .filter-input {
      border-radius: 42px;
      background-color: #fff;
      padding-inline-start: 5px;
      
      > input {
        box-sizing: border-box;
        padding: 21px 16px;  
      }
    }
    & .MuiIconButton-root {
      padding: 0;

      & .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const Filter: React.FC<FilterProps> = ({
  searchText,
  label,
  updateFilterFunction,
  fetchResultsFunction
}) => {
  const dispatch = useAppDispatch();
  const { value: searchTerm, onChange } = useInput("");

  const debouncedSearchTerm = useDebouncedValueUpdate(
    searchTerm.trim() as string,
    500
  );

  useEffect(() => {
    dispatch(updateFilterFunction(debouncedSearchTerm));
  }, [updateFilterFunction, debouncedSearchTerm, dispatch]);

  useEffect(() => {
    dispatch(fetchResultsFunction(0));
  }, [searchText, dispatch, fetchResultsFunction]);

  return (
    <FilterContainer
      variant="outlined"
      className={CLASS_COLLECTION_FILTER_ELEMENT}
    >
      <OutlinedInput
        className="filter-input"
        type="text"
        value={searchTerm}
        onChange={onChange}
        placeholder={label}
      />
    </FilterContainer>
  );
};

export default React.memo(Filter);
