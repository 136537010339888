import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks/redux-hooks";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { LoadingButton } from "@mui/lab";
import { AutocompleteItem, Nullable } from "types/general";
import { ReactComponent as TwoRightArrows } from "assets/svg/TwoRightArrows.svg";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  selectSelectedPolicy,
  selectSelectedPolicyClaims
} from "components/policies/slice/selectors";
import { fetchPolicyClaims } from "components/policies/slice/thunks";
import InputAdornment from "@mui/material/InputAdornment";
import useFormInput from "hooks/useFormInput";
import { validateEmail, validateLettersAndNumbers } from "utils/validation";
import ApiWrapper from "utils/ApiWrapper";
import { getGeoAddress } from "utils/address";
import { ICustomerClaimDto } from "../../../../../types/DTO/ICustomerClaimDto";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Address } from "../../../../../types/quote-types";



const PaymentDrawer = styled(Drawer)`
   {
    position: relative;

    & .MuiDrawer-paper {
      box-sizing: border-box;
      width: 40%;
      flex-shrink: 0;
      margin-top: 72px;
      background-color: var(--navbar-background);
      padding: 20px 40px 72px 20px;
    }

    & .back-button {
      position: absolute;
      justify-content: start;
      margin: 1em;
      margin-left: 0;
      text-transform: unset;
      min-width: 15px;
      left: 13px;
      top: 17px;
    }

    & .back-button:hover {
      background-color: transparent;
    }

    & .divider {
      height: 1px;
      background-color: #d7d7d7;
      margin: 30px 0;
    }

    & .payment-section-container {
      padding: 0 20px;
    }

    & .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      padding: 10px 20px;
    }

    & .subtitle {
      font-weight: 550;
      font-size: 16px;
      line-height: 132.9%;
      padding: 10px 0;
      display: block;
    }

    & .MuiFormControlLabel-label {
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
    }

    & .claim-field {
      max-width: 200px;
      margin: 10px 0;
      height: 50px;
    }

    & .pay-to-fields-container {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        "fname fname lname lname"
        "address address address apt"
        "email email email email"
        "account account routing routing"
        "account-type account-type . .";
    }

    & .account-type-toggle-container {
      border-radius: 24px;
      background: #eef2f9;
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      grid-area: account-type;
      padding: 0 2px;
    }

    & .MuiToggleButtonGroup-grouped {
      width: 50%;
      border: none;
      font-weight: 300;
      font-size: 13px;
      line-height: 18px;
      color: #4f4f4f;
      text-transform: none;
      border-radius: 24px !important;
    }

    & .Mui-selected {
      background-color: #fff;
      border: 1px solid lightgrey !important;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
    }

    & .Mui-selected:hover {
      background-color: #fff;
    }

    & .margin {
      margin: 10px 0;
    }

    & .address-field {
      grid-area: address;
    }

    & .apt-field {
      grid-area: apt;
    }

    & .email-field {
      grid-area: email;
      z-index: 0;
    }

    & .first-name-field {
      grid-area: fname;
    }

    & .last-name-field {
      grid-area: lname;
    }

    & .account-field {
      grid-area: account;
      z-index: 0;
    }

    & .routing-field {
      grid-area: routing;
      z-index: 0;
    }

    & .MuiFormGroup-root {
      flex-direction: row;
    }

    & .claim-radio-btn {
      width: 50%;
    }

    & .amount-section {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    & .approval-btn {
      margin: 30px 0;
      text-transform: none;
    }

    & .pay-to-radio-container {
      margin-bottom: 15px;
    }

    & .error {
      color: var(--text-warning);
    }    
    
    & .autocomplete-class:hover {
      border-color: green;
    }
  }
`;

const CreateNewPaymentButton = styled(Button)`
  border-radius: 4px !important;
  min-height: unset;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
`;

interface PaymentTabProps { }
interface IError {
  message: string;
}

const achBtnText = 'Send for approval';
const walletBtnText = 'Transfer to virtual card';

const accountNumberValidation = (str: string) => {
  const re = /^\d{4,17}$/;
  return re.test(String(str).toLowerCase());
};
const routingNumberValidation = (str: string) => {
  const re = /^\d{9}$/;
  return re.test(String(str).toLowerCase());
};

const CreateNewPayment: React.FC<PaymentTabProps> = () => {
  const dispatch = useAppDispatch();
  const policy = useAppSelector(selectSelectedPolicy);
  const claims = useAppSelector(selectSelectedPolicyClaims);
  const initialFirstName = policy ? `${policy.user.firstName}` : "";
  const initialLastName = policy ? `${policy.user.lastName}` : "";
  const initialHomeAddress = policy
    ? `${policy.user.street},${policy.user.city},${policy.user.state},${policy.user.zip}`
    : "";
  const initialEmail = policy ? `${policy.user.email}` : "";
  const [open, setOpen] = useState(false);
  const [accountType, setAccountType] = useState("checking");
  const [payMethod, setPayMethod] = useState("wallet");
  const [btnText, setBtnText] = useState(walletBtnText);
  const [userClaims, setUserClaims] = useState<AutocompleteItem[]>([]);
  const [claim, setClaim] = useState<AutocompleteItem | null>(null);
  const [amountToPay, setAmountToPay] = useState(0);
  const [enableSend, setEnableSend] = useState(false);
  const [paymentReason, setPaymentReason] = useState("claimRelated");
  const [isLoading, setIsLoading] = useState(false);
  const [residency, setResidency] = useState<Nullable<Address>>(null);
  const [requestFailedMessage, setRequestFailedMessage] = useState<
    null | string
  >(null);
  const featureIsDisabled = true;
  const walletPayload = {
    wallet: {
      userId: policy!.userId,
      amount: amountToPay,
      reason: "credited",
      transferType: payMethod,
      intent: "other",
      userPolicyId: policy ? policy.id : 0,
      claimId: claim ? claim.id : null
    },
    moneyTransferTo: payMethod,
    paymentReason: paymentReason
  };

  const achPayload = {
    bank: {
      userId: policy!.userId,
      amount: amountToPay,
      residency: residency,
      claimId: claim ? claim.id : null,
      routingNumber: "",
      accountNumber: "",
      name: "",
      email: ""
    },
    moneyTransferTo: payMethod,
    paymentReason: paymentReason,
  };

  const {
    value: firstName,
    isValid: firstNameIsValid,
    changeValueHandler: changeFirstName,
    inputBlurHandler: firstNameBlurHandler,
    hasError: firstNameError,
    resetInput: resetFirstName
  } = useFormInput(initialFirstName, (value: string) => value !== "");

  const {
    value: lastName,
    isValid: lastNameIsValid,
    changeValueHandler: changeLastName,
    inputBlurHandler: lastNameBlurHandler,
    hasError: lastNameError,
    resetInput: resetLastName
  } = useFormInput(initialLastName, (value: string) => value !== "");

  const {
    isValid: addressIsValid,
    changeValueHandler: changeAddress,
    resetInput: resetAddress
  } = useFormInput(initialHomeAddress, (value: string) => value !== "");
  const {
    value: apt,
    isValid: aptIsValid,
    changeValueHandler: changeApt,
    inputBlurHandler: aptBlurHandler,
    hasError: aptError,
    resetInput: resetApt
  } = useFormInput("", validateLettersAndNumbers);
  const {
    value: email,
    isValid: emailIsValid,
    changeValueHandler: changeEmail,
    inputBlurHandler: emailBlurHandler,
    hasError: emailError,
    resetInput: resetEmail
  } = useFormInput(initialEmail, validateEmail);
  const {
    value: accountNumber,
    isValid: accountNumberIsValid,
    changeValueHandler: changeAccountNumber,
    inputBlurHandler: accountNumberBlurHandler,
    hasError: accountNumberError,
    resetInput: resetAccountNumber
  } = useFormInput("", accountNumberValidation);
  const {
    value: routingNumber,
    isValid: routingNumberIsValid,
    changeValueHandler: changeRoutingNumber,
    inputBlurHandler: routingNumberBlurHandler,
    hasError: routingNumberError,
    resetInput: resetRoutingNumber
  } = useFormInput("", routingNumberValidation);

  const handleSelectedClaim = useCallback(
    (_: any, newValue: AutocompleteItem | null) => {
      setClaim(newValue);
    },
    []
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    !!newAlignment && setAccountType(newAlignment);
  };

  const handlePayMethodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPayMethod(event.target.value);
    setBtnText(event.target.value === 'ach' ? achBtnText : walletBtnText);
  };

  const resetFields = () => {
    setIsLoading(false);
    setRequestFailedMessage(null);
    setClaim(null);
    setAmountToPay(0);
    setPayMethod("wallet");
    setAccountType("checking");
    setPaymentReason("claimRelated");
    resetFirstName();
    resetLastName();
    resetAddress();
    resetApt();
    resetEmail();
    resetAccountNumber();
    resetRoutingNumber();
  };

  const closeComponent = () => {
    resetFields();
    setOpen(false);
  };

  const sendApproval = async () => {
    setIsLoading(true);
    try {
      if (payMethod === "wallet") {
        const payload = await ApiWrapper.request<IError>(`payment`, "POST", {
          data: walletPayload
        });
        if (!!payload?.message) {
          setRequestFailedMessage(payload.message);
        } else {
          closeComponent();
        }
      }
      else if (payMethod === "ach") {
        achPayload.bank.routingNumber = routingNumber;
        achPayload.bank.accountNumber = accountNumber;
        achPayload.bank.name = `${firstName} ${lastName}`;
        achPayload.bank.email = email;
        achPayload.bank.residency = achPayload.bank.residency || await getGeoAddress(initialHomeAddress);

        const payload = await ApiWrapper.request<IError>(`payment`, "POST", {
          data: achPayload
        });
        if (!!payload?.message) {
          setRequestFailedMessage(payload.message);
        } else {
          closeComponent();
        }
      }
    } catch (e: any) {
      setRequestFailedMessage(e.message ?? "Network error");
    }
  };

  const setCityAndCountry = async ({ label }: any) => {

    changeAddress({ target: { value: label } } as React.ChangeEvent<HTMLInputElement>)
    const geoAddress = await getGeoAddress(label);
    geoAddress && setResidency(geoAddress);

  };

  useEffect(() => {
    if (!!policy) {
      dispatch(fetchPolicyClaims({ userId: policy.userId }));
    }
  }, [policy, dispatch]);

  useEffect(() => {
    setUserClaims(
      claims.map((claim: ICustomerClaimDto) => ({
        label: `${claim.claimIdentifier}`,
        id: claim.id
      }))
    );
  }, [claims]);

  useEffect(() => {
    let enabled = amountToPay > 0;
    if (enabled && payMethod === "ach") {
      enabled =
        firstNameIsValid &&
        lastNameIsValid &&
        addressIsValid &&
        emailIsValid &&
        accountNumberIsValid &&
        routingNumberIsValid;
    }
    if (enabled && paymentReason === "claimRelated") {
      enabled = claim !== null;
    }
    setEnableSend(enabled);
  }, [
    claim,
    firstNameIsValid,
    lastNameIsValid,
    addressIsValid,
    emailIsValid,
    paymentReason,
    accountNumberIsValid,
    routingNumberIsValid,
    aptIsValid,
    payMethod,
    amountToPay
  ]);

  // @ts-ignore
  const payToFields =
    payMethod === "ach" ? (
      <div className="pay-to-fields-container">
        <TextField
          label="First name"
          variant="outlined"
          size="small"
          className="first-name-field"
          value={firstName}
          onChange={changeFirstName}
          error={firstNameError}
          onBlur={firstNameBlurHandler}
          disabled={isLoading}
          helperText={firstNameError ? "*Invalid first name" : null}
        ></TextField>
        <TextField
          label="Last name"
          variant="outlined"
          size="small"
          className="last-name-field"
          value={lastName}
          onChange={changeLastName}
          onBlur={lastNameBlurHandler}
          error={lastNameError}
          disabled={isLoading}
          helperText={lastNameError ? "*Invalid last name" : null}
        ></TextField>

        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY!}
          autocompletionRequest={{
            componentRestrictions: {
              country: ["us"]
            }
          }}
          selectProps={{
            defaultInputValue: initialHomeAddress,
            onChange: setCityAndCountry,
            isDisabled: isLoading,
            placeholder: "Home address",
            className: 'autocomplete-class address-field',
            styles: {
              control: (provided: any, state: any) => ({
                ...provided,
                backgroundColor: 'inherit',
                '&:hover': {
                  border: '1px solid black',
                },
              }),
              option: (provided: any) => ({
                ...provided,
                zIndex: '999'
              }),
              indicatorsContainer: (provided: any) => ({
                ...provided,
                display: 'none'
              }),
            }
          }}
        />

        <TextField
          label="Apt #"
          variant="outlined"
          size="small"
          className="apt-field"
          value={apt}
          onChange={changeApt}
          error={aptError}
          onBlur={aptBlurHandler}
          disabled={isLoading}
          helperText={aptError ? "*Invalid apt #" : null}
        ></TextField>
        <TextField
          label="Email address"
          variant="outlined"
          type="email"
          size="small"
          className="email-field"
          value={email}
          onChange={changeEmail}
          error={emailError}
          onBlur={emailBlurHandler}
          disabled={isLoading}
          helperText={emailError ? "*Invalid email address" : null}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon fontSize="small" color="primary" />
              </InputAdornment>
            )
          }}
        ></TextField>
        <TextField
          label="Account #"
          variant="outlined"
          size="small"
          className="account-field"
          value={accountNumber}
          onChange={changeAccountNumber}
          error={accountNumberError}
          disabled={isLoading}
          onBlur={accountNumberBlurHandler}
          helperText={
            accountNumberError
              ? "*Account number must contain a 4 to 17 digit number"
              : null
          }
        ></TextField>
        <TextField
          label="Routing #"
          variant="outlined"
          size="small"
          className="routing-field"
          value={routingNumber}
          onChange={changeRoutingNumber}
          error={routingNumberError}
          disabled={isLoading}
          onBlur={routingNumberBlurHandler}
          helperText={
            routingNumberError
              ? "*Routing number must contain a 9 digit number"
              : null
          }
        ></TextField>

        <ToggleButtonGroup
          className="account-type-toggle-container margin"
          value={accountType}
          exclusive
          onChange={handleChange}
          size="small"
        >
          <ToggleButton
            size="small"
            value="checking"
            className="account-type-toggle"
            disableRipple={true}
            disabled={isLoading}
          >
            Checking
          </ToggleButton>
          <ToggleButton
            disableRipple={true}
            disabled={isLoading}
            size="small"
            value="saving"
            className="account-type-toggle"
          >
            Saving
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    ) : (
      <></>
    );

  const button = !requestFailedMessage ? (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      className="approval-btn"
      onClick={sendApproval}
      disabled={!enableSend}
    >
      {btnText}
    </LoadingButton>
  ) : (
    <Button
      variant="contained"
      className="approval-btn"
      onClick={closeComponent}
    >
      Close
    </Button>
  );

  return (
    <>
      <PaymentDrawer anchor={"right"} hideBackdrop={true} open={open}>
        <Button
          disabled={isLoading}
          fullWidth={false}
          variant="text"
          onClick={() =>
            dispatch(() => {
              resetFields();
              setOpen(false);
            })
          }
          className="back-button"
          size="small"
          disableRipple
          startIcon={<TwoRightArrows />}
        ></Button>
        <span className="title"> Create payment</span>
        <div className="payment-section-container">
          <RadioGroup
            value={paymentReason}
            onChange={({ target }) => setPaymentReason(target.value)}
            name="paymentType"
            className="payment-type-container"
          >
            <FormControlLabel
              value="claimRelated"
              control={<Radio />}
              label="Claim related"
              className="claim-radio-btn"
              disabled={isLoading}
            />
            <FormControlLabel
              value="marketing"
              control={<Radio />}
              label="Marketing"
              disabled={isLoading || featureIsDisabled}
            />
          </RadioGroup>

          {paymentReason === "claimRelated" && (
            <Autocomplete
              className="claim-field"
              onChange={handleSelectedClaim}
              value={claim}
              renderInput={(params) => (
                <TextField {...params} label="#claims" />
              )}
              options={userClaims}
              disabled={isLoading}
            />
          )}
          <div className="divider"></div>
          <span className="subtitle">Pay to</span>

          <RadioGroup
            defaultValue="wallet"
            name="radio-buttons-group"
            value={payMethod}
            onChange={handlePayMethodChange}
            className="pay-to-radio-container"
          >
            <div>
              <FormControlLabel
                value="wallet"
                control={<Radio />}
                label="Faye wallet"
                disabled={isLoading}
              />
              <FormControlLabel
                disabled={isLoading}
                value="ach"
                control={<Radio />}
                label="ACH"
              />
              <FormControlLabel
                value="check"
                control={<Radio />}
                label="Check"
                disabled={isLoading || featureIsDisabled}
              />
            </div>
          </RadioGroup>

          {payToFields}
          <div className="divider"></div>
          <div className="amount-section">
            <span className="subtitle">Amount to pay</span>
            <TextField
              defaultValue={0}
              onChange={({ target }) => {
                setAmountToPay(+target.value);
              }}
              type="number"
              label="USD"
              inputProps={{
                step: 50,
                min: 0
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
              disabled={isLoading}
            ></TextField>
            <p className="error">{requestFailedMessage}</p>
            {button}
          </div>
        </div>
      </PaymentDrawer>
      <CreateNewPaymentButton
        variant="outlined"
        className="create-payment-btn"
        size="small"
        onClick={() => setOpen(true)}
      >
        <span> + Create new payment</span>
      </CreateNewPaymentButton>
    </>
  );
};

export default CreateNewPayment;
