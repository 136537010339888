import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { fetchPolicyPage } from "./slice/thunks";
import {
  policiesLoading,
  selectPolicies,
  selectPoliciesPagination,
  selectPoliciesSearchText
} from "./slice/selectors";
import styled from "styled-components";
import Box from "@mui/material/Box";

import { GRID_AREA_SECTION_COLLECTION } from "../../constants/common";
import PaginationController from "../PaginationController";
import { updatePolicySearchText } from "./slice";
import Filter from "../Filter";
import PolicyLine from "./policy-line";
import SpinnerLoader from "../ui/loaders/SpinnerLoader/SpinnerLoader";
import { IUserPolicyInfoDto } from "types/DTO/IUsersInfoDto";

const CLASS_TABLE_WRAPPER = "table-wrapper";

interface PoliciesListProps { }

const TableWrapper = styled(Box)`
  &.${CLASS_TABLE_WRAPPER} {
    height: max-content;
  }
`;

const PoliciesListContainer = styled.div`

  .header {
    margin: 0 auto 24px;
    max-width: 1200px;
    width: 100%;
  
    h2 { 
      font-size: 32px; 
      margin: 0 0 24px;
    }
    & .collection-filter-element {
      width: 45vw;
    }
  }
  &.${GRID_AREA_SECTION_COLLECTION} {
    font-style: normal;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-content: start;
    background: #f4f4f4;
    min-height: 100%;

    & .headers {
      color: #959493;
      font-size: 12px;
      margin-bottom: 8px;
    }

    & .no-policies {
      margin: auto;
    }

    & .table-row {
      max-width: 1200px;
    }

    & .row {
      margin: 10px auto;
      display: flex;
      flex-direction: row;

      & .img-url-container {
        flex: 3;
      }

      & .user-name-container {
        flex: 3;
      }

      & .email-container {
        flex: 4;
      }

      & .code-container {
        flex: 3;
      }

      & .mobile-container {
        flex: 1;
      }

      & .trip-status-container {
        flex: 2;
      }

      & .handler-container {
        flex: 1;
        position: relative;
        margin-top: 5px;
      }
    }
  }
`;

const PoliciesList: React.FC<PoliciesListProps> = () => {
  const dispatch = useAppDispatch();
  const policies = useAppSelector(selectPolicies);
  const pagination = useAppSelector(selectPoliciesPagination);
  const searchText = useAppSelector(selectPoliciesSearchText);
  const isLoading = useAppSelector(policiesLoading);
  const currentPage = pagination ? pagination.currentPage : 0;

  useEffect(() => {
    dispatch(fetchPolicyPage(currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const content = isLoading ? (
    <SpinnerLoader isLoading={true} />
  ) : !!policies?.length ? (
    <TableWrapper className={`${CLASS_TABLE_WRAPPER} ag-theme-alpine`}>
      <div className="row headers table-row">
        <div className="img-url-container"></div>
        <div className="user-name-container">Name</div>
        <div className="email-container">Email</div>
        <div className="code-container">Policy</div>
        <div className="mobile-container"></div>
        <div className="trip-status-container">Status</div>
        <div className="handler-container"></div>
      </div>
      {policies?.map((policy: IUserPolicyInfoDto) => (
        <PolicyLine key={policy.id} policy={policy}></PolicyLine>
      ))}

      <PaginationController
        pagination={pagination}
        fetchFunction={fetchPolicyPage}
      />
    </TableWrapper>
  ) : (
    <p className="no-policies">No policies found</p>
  );

  return (
    <PoliciesListContainer className={GRID_AREA_SECTION_COLLECTION}>
      <div className="header">
        <h2>Policies</h2>
        <Filter
          updateFilterFunction={updatePolicySearchText}
          fetchResultsFunction={fetchPolicyPage}
          label="Search by name or Email"
          searchText={searchText}
        />
      </div>
      {content}
    </PoliciesListContainer>
  );
};

export default React.memo(PoliciesList);
