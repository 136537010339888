import { Nullable } from "types/general";
import { Address } from "types/quote-types";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { IIndexable } from "types/general";

const types = new Map<string, string>([
    ['country', 'short_name'],
    ['administrative_area_level_1', 'short_name'],
    ['locality', 'long_name'],
    ['postal_code', 'long_name'],
    ['route', 'long_name'],
    ['street_number', 'long_name'],
]);

export async function getGeoAddress(addressLabel: string): Promise<Nullable<Address>> {
    try {
        const geocodeAddress = await geocodeByAddress(addressLabel).then(res => res && res[0]?.address_components);
        if (!geocodeAddress) return null;

        const address = geocodeAddress.reduce((acc, val) => {
            const type = val.types.find((t) => types.has(t));
            if (type) {
                const t = types.get(type) || "short_name";
                const v = (val as unknown as IIndexable<string>);
                acc[type] = v[t];
            }
            return acc;
        }, {} as IIndexable<string>);


        return {
            country: address.country,
            state: address.administrative_area_level_1,
            postalCode: address.postal_code,
            city: address.locality,
            streetAddress: `${address.street_number} ${address.route}`
        };

    } catch (error) {
        console.error(error);
        return null;
    }
}